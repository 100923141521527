module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Relution","short_name":"Relution","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"./src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4d0afc82775c5f132c6b655e80a27654"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["de","en","es","fr","it","tr"],"defaultLanguage":"de","siteUrl":"http://relution.pages.office.mwaysolutions.com/relution-website-relaunch/","i18nextOptions":{"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/:any*","getLanguageFromPath":true,"languages":[]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
