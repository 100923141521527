import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/400-italic.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/800.css'
import 'flickity/css/flickity.css'
import './src/css/index.css'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./src/mocks/browser')
    worker.start({
      onUnhandledRequest: 'bypass',
    })
  }
}
