exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-insight-jsx": () => import("./../../../src/templates/insight.jsx" /* webpackChunkName: "component---src-templates-insight-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-partner-jsx": () => import("./../../../src/templates/partner.jsx" /* webpackChunkName: "component---src-templates-partner-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-release-note-jsx": () => import("./../../../src/templates/release-note.jsx" /* webpackChunkName: "component---src-templates-release-note-jsx" */)
}

